import baguetteBox from 'baguettebox.js';

export default class Custom {
  
  constructor() {
    
    $('.section__slider').slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: false,
      arrow: true
    });
    
    $('.section__slider-box').slick({
      slidesToShow: 4,
      slidesToScroll: 1,
      dots: false,
      arrow: true,
      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 3,
            arrow: false
          }
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            arrow: false
          }
        },
      ]
    });
    
    $('.section__slider-box-three').slick({
      slidesToShow: 3,
      slidesToScroll: 1,
      dots: false,
      arrow: true,
      responsive: [
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            arrow: false
          }
        },
      ]
    });
    
    $('.image-slider').slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      asNavFor: '.text-slider'
    });
    
    //$('.text-slider').on('init', function(event, slick) {
    //$('.text-slider-single').css('height', $('.text-slider').height())
    //});
    
    $('.text-slider').slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      asNavFor: '.image-slider',
      dots: false,
      prevArrow: $('.text-slider').parent().find('.slick-prev'),
      nextArrow: $('.text-slider').parent().find('.slick-next'),
      fade: true,
    });
    
    // Scroll to top //
    $('.totop').on('click', 'a', function(e) {
      $('html, body').animate({
        scrollTop: '0px'
      },
      1500);
      e.preventDefault();
    });
    
    $(window).scroll(function () {
      var scrollTop = $(window).scrollTop() || $('html,body').scrollTop()
      if (scrollTop > 900) {
        $('.totop').addClass('totop--active')
      } else {
        $('.totop').removeClass('totop--active')
      }
    })
    
    // search bar
    if ($(window).width() < 767) {
      $('.header .header--menubar').on('click', '.header--search', function(e){
        $('.overlay').toggleClass('open');
      });
    } else {
      $('.header .header--menubar').on('click', '.header--search', function(e){
        $('.header--search_open').toggleClass('open');
      });
    }
    
    $(document).mouseup(function (e){
      var container = $('.header--menubar .header--search'),
      container2 = $('.header--search_open form');
      
      if (!container.is(e.target) && container.has(e.target).length === 0 && !container2.is(e.target)
      && container2.has(e.target).length === 0)
      {
        $('.header--search_open').removeClass('open');
      }
    });
    
    $('.header--search_open form').on('click', 'span', function() {
      if ($(this).next().val() == '') {
        $('.header--search_open').removeClass('open');
      } else {
        $(this).next().val('')
      }
    })
    
    $('.hero .search').on('keyup', 'input', function () {
      if ($(this).val() != '') {
        $('.hero .search').addClass('search--active')
      } else {
        $('.hero .search').removeClass('search--active')
      }
    })
    
    $('.hero .search').on('click', 'span', function() {
      $(this).next().val('')
      $(this).parents('.search').removeClass('search--active')
    })
    
    // megamenu
    //$('.header .header--menu').on('click', '.menu-item-has-children', function(e) {
    //e.preventDefault();
    //$('.menu-item-has-children').removeClass('open');
    //$(this).toggleClass('open');
    //});
    
    $(document).mouseup(function(e) {
      var container = $('.menu-bg'),
      container2 = $('.header .header--menubar .header--menu ul li a');
      
      if (!container.is(e.target) && container.has(e.target).length === 0 && !container2.is(e.target)
      && container2.has(e.target).length === 0)
      {
        $('.menu-item-has-children').removeClass('open');
      }
    });
    
    // font size switcher
    $('.change-font').on('click', 'a', function(e){
      e.preventDefault();
      $('.change-font a.size').removeClass('active');
      $(this).addClass('active');
      if($(this).hasClass('font-size-1')) {
        $('body').removeClass('text-size2 text-size3');
      }else if($(this).hasClass('font-size-2')) {
        $('body').removeClass('text-size3').addClass('text-size2');
      }else if($(this).hasClass('font-size-3')) {
        $('body').removeClass('text-size2').addClass('text-size3');
      }
    });
    
    // mobile menu
    $(document).on('click', '.menu-hamburger', function(e) {
      if($('.overlay').hasClass('open')) {
        $('.overlay').removeClass('open');
      } else {
        $('.overlay').addClass('open');
      }
      e.preventDefault();
    });
    
    $(document).mouseup(function (e){
      var container = $('.box-wrap');
      
      if (!container.is(e.target) && container.has(e.target).length === 0) {
        $('.overlay').removeClass('open');
      }
    });
    
    // mobile footer
    $('.footer .footer-col').on('click', 'h4', function(e){
      $(this).toggleClass('active');
      $(this).parent().find('ul').toggleClass('open');
      e.preventDefault();
    });
    
    // convert am and pm to a.m. and p.m.
    $('.time').each(function() {
      var text = $(this).html().replace(/\am/g, 'a.m.').replace(/\pm/g, 'p.m.');
      $(this).html(text);
    });
    
    // remove inline color attribute from warning-text class
    $('.warning-text').each(function() {
      $(this).css("color", "");
    });
    
    // initialize gallery baguetteBox
    baguetteBox.run('.gallery');
    
    // Fix pagination in IE
    if (window.document.documentMode) {
      var paginationWidth = $('.pagingControls li').length*36;
      $('.pagination.float-right').css("width", paginationWidth + "px");
    }
    
    
    // function for email links
    function mt(n,d,s,b) {
      var hrefLink = "mailto:" + n + "@" + d;
      if(s){
        hrefLink += connector + "subject=" + encodeURI(s);
        connector = "&";
      }
      if(b){
        hrefLink += connector + "body=" + encodeURI(b);
      }
      location.href = hrefLink;
    }
  }
}