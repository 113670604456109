'use strict';

// Packages
import 'jquery';
import 'popper.js';
import 'bootstrap';
import 'slick-carousel';
import Custom from './custom';

$(function() {
  new Custom();
});